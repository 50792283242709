<template>
  <header class="header uk-light">
    <div class="container">
      <nav uk-navbar>
        <!-- left Side Content -->
        <div class="uk-navbar-left">
          <!-- menu icon -->
          <span class="mmenu-trigger" uk-toggle="target: #wrapper ; cls: mobile-active">
            <button class="hamburger hamburger--collapse" type="button">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </span>
          <div class="searchbox uk-visible@s" :class="{ hide: !seen }" id="hide">
            <input class="uk-search-input" placeholder="Arama yap" type="search" />
            <button class="btn-searchbox"></button>
          </div>
          <!-- Search box dropdown -->
          <div class="dropdown-search" uk-dropdown="pos: top;mode:click;animation: uk-animation-slide-bottom-small">
            <div class="erh BR9 MIw"
              style="top: -26px;position: absolute ; left: 24px;fill: currentColor;height: 24px;pointer-events: none;color: #f5f5f5;">
              <svg height="22" width="22">
                <path d="M0 24 L12 12 L24 24"></path>
              </svg>
            </div>
            <!-- User menu -->
            <ul class="dropdown-search-list">
              <li class="list-title">
                Son Aramalar
              </li>
              <li>
                <a href="course-intro.html"> Kullanıcı Ekle</a>
              </li>
              <li><a href="course-intro.html"> Düello Raporları </a></li>
              <li><a href="course-intro.html"> Soru Havuzu </a></li>
              <li class="menu-divider"></li>
              <li><a href="course-intro.html"> Sevil Yıldırım</a></li>
              <li><a href="course-intro.html"> Ahmet Güler </a></li>
            </ul>
          </div>
        </div>
        <!--  Right Side Content   -->
        <div class="uk-navbar-right">
          <div class="header-widget">
            <!-- Language  -->
            <a :uk-tooltip="`title: Kullanıcı Sayfasından çıkış yap`" style="color:red" class="header-widget-icon"
              v-if="superAdmin() && superAdminToken" @click="leaveFromUser()">
              <i class="uil-eye"></i>
            </a>
            <a :uk-tooltip="`title: ${$t('home.languages')} ; pos: bottom ;offset:0`
              " class="header-widget-icon" href="javascript:void(0)">
              <img class="h-23px w-23px rounded-sm" :src="languageFlag || getLanguageFlag" alt="" />
            </a>
            <!-- Language dropdown -->
            <div ref="languageRefs" class="dropdown-notifications dropdown-languages"
              uk-dropdown=" pos: top-right;mode:click">
              <!-- notification contents -->
              <div class="dropdown-notifications-content" data-simplebar>
                <!-- notiviation list -->
                <ul style="background-color:white">
                  <template v-for="(item, i) in languages">
                    <li v-if="item.lang !== activeLanguage" class="navi-item" :class="{
                      'navi-item-active': isActiveLanguage(item.lang),
                    }" :key="i">
                      <a href="#" class="navi-link" v-bind:data-lang="item.lang" v-on:click="selectedLanguage">
                        <span class="symbol symbol-20 mr-3">
                          <img :src="item.flag" alt="" class="h-23px w-23px rounded-sm" />
                        </span>
                        <span class="navi-text">{{ item.name }}</span>
                      </a>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <!-- notificiation icon  -->
            <a class="header-widget-icon" href="#" :uk-tooltip="`title: ${$t('home.Notifications')}  ; pos: bottom ;offset:0`
              ">
              <i class="uil-bell"></i>
              <span v-if="notifCount > 0">{{ notifCount }}</span>
            </a>
            <!-- notificiation dropdown -->
            <!-- notificiation dropdown -->
            <div ref="notificationRef" class="dropdown-notifications"
              uk-dropdown="pos: top-right;mode:click; animation: uk-animation-slide-bottom-small">
              <!-- notivication header -->
              <div class="dropdown-notifications-headline">
                <h4>{{ $t("home.Notifications") }}</h4>
              </div>

              <!-- notification contents -->
              <div class="dropdown-notifications-content" data-simplebar>
                <!-- notiviation list -->

                <ul>
                  <template v-for="(item, index) in notificationItems">
                    <li v-if="'header_notification_item_' + index" :key="'header_notification_item_' + index">
                      <template v-if="typeof item.parsed_data.webUrl !== 'undefined' &&
                        item.parsed_data.webUrl &&
                        typeof item.parsed_data.webUrl.name !==
                        'undefined' &&
                        item.parsed_data.webUrl.name !== '' &&
                        typeof item.parsed_data.webUrl.params !==
                        'undefined'
                        ">
                        <a href="#" @click.prevent="
                          closeDropdown(
                            item.parsed_data.webUrl.name,
                            'notificationRef',
                            item.parsed_data.webUrl.params,
                            item.id
                          )
                          ">
                          <span class="notification-icon btn btn-soft-info"><i class="icon-feather-bell"></i></span>
                          <span class="notification-text" :class="{ 'font-weight-bold': !item.read_at }">
                            <template v-if="typeof item.parsed_data.message !== 'undefined'
                              ">{{ item.parsed_data.message }}</template>
                            <template v-else>Bir bildiriminiz var</template>
                            <span class="time-ago d-block">{{
                              fixDateToNow(
                                item.updated_at
                                  ? item.updated_at
                                  : item.created_at
                              )
                            }}</span>
                          </span>
                        </a>
                      </template>
                      <template v-else>
                        <a @click="
                        closeDropdown(
                          'dashboard.notification',
                          'notificationRef',
                          null,
                          item.id
                        )
                          ">
                          <span class="notification-icon btn btn-soft-info"><i class="icon-feather-bell"></i></span>
                          <span class="notification-text" :class="{
                            'font-weight-bold':
                              item && item.pivot && +item.pivot.is_read === 0,
                          }">
                            <template v-if="typeof item.parsed_data.message !== 'undefined'
                              ">{{ item.parsed_data.message }}</template>
                            <template v-else>Bir bildiriminiz var</template>
                            <span class="time-ago d-block">{{
                              fixDateToNow(
                                item.updated_at
                                  ? item.updated_at
                                  : item.created_at
                              )
                            }}</span>
                          </span>
                        </a>
                      </template>
                    </li>
                  </template>
                </ul>
              </div>

              <div class="dropdown-notifications-footer">
                <a href="#" @click.prevent="
                  $router.push({ name: 'dashboard.notification' })
                  ">
                  {{ $t("home.see_all") }}
                  <i class="icon-line-awesome-long-arrow-right"></i>
                </a>
              </div>
            </div>
            <!-- Message  -->
            <a class="header-widget-icon" href="#" :uk-tooltip="`title: ${$t('home.Messages')} ; pos: bottom ;offset:0`
              ">
              <i class="uil-envelope-alt"></i>
              <span v-if="itemCount > 0">{{ itemCount }}</span>
            </a>
            <!-- Message  notificiation dropdown -->
            <div ref="messageRefs" class="dropdown-notifications" uk-dropdown=" pos: top-right;mode:click">
              <!-- notivication header -->
              <div class="dropdown-notifications-headline">
                <h4>Mesajlar</h4>
                <a href="#">
                  <i class="icon-feather-settings" uk-tooltip="title: Mesaj Ayarları ; pos: left"></i>
                </a>
              </div>
              <!-- notification contents -->
              <div class="dropdown-notifications-content" data-simplebar>
                <!-- notiviation list -->
                <ul>
                  <li v-for="(item, index) in messageItems" :key="index">
                    <a @click="
                      closeDropdown('User.messages', 'messageRefs', item)
                      ">
                      <span class="notification-avatar">
                        <img :alt="item.fullname" :src="item.user?.photo_link" />
                      </span>
                      <div class="notification-text notification-msg-text">
                        <strong>{{ item.fullname }}</strong>
                        <p>{{ item.message }}</p>
                        <span class="time-ago d-block">{{
                          fixDateToNow(item.date)
                        }}</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="dropdown-notifications-footer">
                <a @click="closeDropdown('User.messages', 'messageRefs')">
                  {{ $t("home.see_all") }}
                  <i class="icon-line-awesome-long-arrow-right"></i>
                </a>
              </div>
            </div>
            <!-- profile-icon-->
            <div class="dropdown-user-details">
              <div class="dropdown-user-avatar">
                <img :src="user_header.photo" alt="" />
              </div>
              <div class="dropdown-user-name">
                {{ user_header.name + " " + user_header.surname }}
                <span>{{ user_header.company_name }}</span>
              </div>
            </div>
            <div ref="profileRefs" class="dropdown-notifications small" uk-dropdown="pos: top-right ;mode:click">
              <!-- User menu -->
              <ul class="dropdown-user-menu">
                <li></li>
                <li>
                  <router-link :to="{ name: 'user.index' }">
                    <i class="uil-user-circle"></i> Kullanıcı Olarak Giriş Yap
                  </router-link>
                </li>
                <li>
                  <a @click="closeDropdown('company.setting', 'profileRefs')">
                    <i class="icon-feather-settings"></i> Ayarlar</a>
                </li>
                <ul class="menu-divider">
                  <li>
                    <a @click="closeDropdown('User.faq', 'profileRefs')">
                      <i class="icon-feather-help-circle"></i> Sıkça Sorulan
                      Sorular</a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="onLogout">
                      <i class="icon-feather-log-out"></i> Oturumu Kapat</a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <!-- icon search-->
          <a class="uk-navbar-toggle uk-hidden@s" href="#" uk-toggle="target: .nav-overlay; animation: uk-animation-fade">
            <i class="uil-search icon-small"></i>
          </a>
          <!-- User icons -->
          <span class="uil-user icon-small uk-hidden@s" uk-toggle="target: .header-widget ; cls: is-active">
          </span>
        </div>
        <!-- End Right Side Content / End -->
      </nav>
    </div>
    <!-- container  / End -->
  </header>
</template>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import NotificationItem from "@/view/components/notification/NotificationItem";
import { GET_ITEMS } from "@/core/services/store/REST.module";
import {
  MODULE_NAME as COMPANY_MODULE_NAME,
  ONLINE_USERS,
} from "@/core/services/store/company.module";
import store from "@/core/services";
import moment from "moment";
import _ from "lodash";
import EventBus from "@/view/components/event-bus";
import i18nService from "@/core/services/i18n.service.js";
import axios from "axios";
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: "aes", isCompression: true });
export default {
  name: "Header",
  components: {
    NotificationItem,
  },
  data() {
    return {
      superAdminToken: null,
      notificationUrl: "api/notifications", //"api/user/notice",
      messagesUrl: "api/user-messages",
      messagesCountUrl: "api/user-messages-count",
      notificationItems: [],
      messageItems: [],
      itemCount: 0,
      notifCount: 0,
      seen: false,
      languages: i18nService.languages,
      languageFlag: "",
    };
  },
  methods: {
    superAdmin() {
      if (this.user_header === null || !("roles" in this.user_header)) {
        return false;
      }
      if (this.user_header.roles.length > 2) {
        return true;
      } else {
        return false;
      }
    },
    leaveFromUser() {
      axios.delete('api/impersonate/leaving', {
        headers: this.headers
      })
        .then((response) => {
          if (this.superAdminToken) {
            ls.set("token", this.superAdminToken);
            window.localStorage.removeItem('superAdminToken');
            location.reload();
          }
        })
    },
    getNotifCount() {
      let self = this;
      self.$store
        .dispatch(GET_ITEMS, {
          url: this.notificationUrl,
          filters: {
            onlyCount: true,
          },
        })
        .then((result) => {
          self.notifCount = result.data;
        });
    },
    closeDropdown(route, refName, params = {}, itemId = 0) {
      if (refName === "notificationRef") {
        params = _.merge({}, { id: itemId }, params);
      }
      if (refName === "messageRefs") {
        params = _.merge({}, { message: params });
      }
      this.$router.push({ name: route, params: params });
      UIkit.dropdown(this.$refs[refName]).hide(0);
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    myProfile() {
      this.$router.push({ name: "profile.edit" });
    },
    getNotificationItems() {
      let self = this;
      self.$store
        .dispatch(GET_ITEMS, {
          url: this.notificationUrl,
          filters: {
            limit: 10,
          },
          acceptPromise: false,
        })
        .then((result) => {
          let formattedItems = [];
          result.data.forEach((item) => {
            if (item.type == 1) {
              item.parsed_data = JSON.parse(item.data);
              formattedItems.push(item);
            } else {
              item.parsed_data = {
                apiUrl: null,
                message: item.name,
                text: item.text,
                webUrl: null,
              };
              formattedItems.push(item);
            }
          });
          self.notificationItems = formattedItems;
        });
    },
    getMessages() {
      let self = this;
      self.$store
        .dispatch(GET_ITEMS, {
          url: this.messagesUrl,
          filters: {},
          acceptPromise: false,
        })
        .then((response) => {
          let items = response.data;
          items.forEach((item) => {
            let chatUsers = item.group.users;
            item.user = chatUsers.find(
              (subItem) => subItem.id === item.sender_user_id
            );
          });
          self.messageItems = items.reverse();
        });
    },
    getMessagesCount() {
      let self = this;
      self.$store
        .dispatch(GET_ITEMS, {
          url: this.messagesCountUrl,
          filters: {},
          acceptPromise: false,
        })
        .then((response) => {
          self.itemCount = response.data;
        });
    },
    fixDateToNow(value) {
      return moment(value).fromNow();
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    selectedLanguage(e) {
      if (e) {
        const el = e.target.closest(".navi-link");
        const lang = el.getAttribute("data-lang");

        i18nService.setActiveLanguage(lang);
        this.$emit(
          "language-changed",
          this.languages.find((val) => {
            return val.lang === lang;
          })
        );
        window.location.reload();
      }
      // else if ( !== i18nService.setActiveLanguage(lang)) {
      //   this.$emit("language-changed", activeLanguage);
      //   window.location.reload();
      // }
    },
    isActiveLanguage(current) {
      return this.activeLanguage === current;
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
  },
  computed: {
    online_users: {
      get() {
        return store.getters[COMPANY_MODULE_NAME + "/" + ONLINE_USERS];
      },
      set(value) { },
    },
    loginLogo() {
      return process.env.BASE_URL + "media/images/avatars/avatar-2.jpg";
    },
    user_header: {
      get() {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set() { },
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
  },
  mounted() {
    this.superAdminToken = ls.get("superAdminToken");
    this.getNotificationItems();
    this.getMessages();
    this.getNotifCount();
    this.getMessagesCount();
    EventBus.$on("message_count", () => this.getMessagesCount());
  },
  watch: {
    $route(to, from) {
      let refs = ["profileRefs", "messageRefs", "notificationRef"];
      refs.map((item) => {
        UIkit.dropdown(this.$refs[item]).hide(0);
      });
    },
  },
};
</script>

<style scoped>
.hide {
  visibility: hidden !important;
}
</style>
